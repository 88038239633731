<template>
  <div class="content">
    <div class="center fs-18">
      {{ advertInfoList.adTitle }}
    </div>
    <div style="margin-top: 20px; margin-bottom: 20px;" class="rich-text">
      <div v-html="advertInfoList.adContent"/>
    </div>
  </div>
</template>
<script>
import { CloudUser } from '../../apis'
export default {
  data () {
    return {
      advertInfoList: []
    }
  },
  methods: {
  },
  created () {
    this.$http
      .get(CloudUser.advertInfo, {
        id: this.$route.query.advertId
      })
      .then(res => {
        if (res.data.success) {
          this.advertInfoList = res.data.data
        } else {
          this.$message.error(res.data.errorMsg)
        }
      })
      .catch(() => {
        this.$message.error('服务器异常')
      })
  }
}
</script>

<style scoped lang="scss">
.content {
  font-size: 14px;
  background-color: #ffffff;
  padding: 10px;
}

.fs-18 {
  font-size: 18px;
}

p {
  text-indent: 25px;
  margin: 0;
}

.center {
  text-align: center;
}

.fw-500 {
  font-weight: 600;
}
</style>
<style lang="scss">
.rich-text{
  img{
    max-width: 100% !important;
    height: auto;
  }
}
</style>
